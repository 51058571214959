<template>
  <router-view />
</template>

<style lang="scss">
@import './components/common.scss';
html,
body {
  width: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  margin: 0;
  left: 0;
  font-family: $font-family;
  height: 100%;
}

#app {
  display: flex;
  width: 100%;
  font-family: $font-family;
  height: 100%;
}
</style>
