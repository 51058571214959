export const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login/Login.vue'),
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/Company/Company.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/User/User.vue'),
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/Product/Product.vue'),
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('../views/Inventory/Inventory.vue'),
  },
  {
    path: '/pending-inventory',
    name: 'pending-inventory',
    component: () => import('../views/PendingInventory/PendingInventory.vue'),
  },
  {
    path: '/exported-inventory',
    name: 'exported-inventory',
    component: () => import('../views/ExportedInventory/ExportedInventory.vue'),
  },
  {
    path: '/nfe-invoice',
    name: 'nfe-invoice',
    component: () => import('../views/NfeInvoice/NfeInvoice.vue'),
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('../views/Config/Config.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/Terms/Terms.vue'),
  },
]
