import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from './store'
import VueToast from 'vue-toast-notification'
import vSelect from 'vue-select'
import VueBaseTooltip from 'vue-base-tooltip'
import 'vue-base-tooltip/style.css'
import Maska from 'maska'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'vue-select/dist/vue-select.css'

createApp(App)
  .use(router)
  .use(store)
  .use(VueToast)
  .use(VueBaseTooltip)
  .use(Maska)
  .component('v-select', vSelect)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
