import { AccessLevelEnum } from '@/enum/access-leval.enum'
import { createStore } from 'vuex'
import userStore from './user.store'

const store = createStore({
  modules: {
    user: { ...userStore },
  },
})

export default store
