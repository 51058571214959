import { AccessLevelEnum } from '@/enum/access-leval.enum'
import { CompanyTypeEnum } from '@/enum/company-type.enum'
import { userAuthInterface } from '@/util/interface'
import { Module } from 'vuex'

const userStore: Module<any, any> = {
  namespaced: true,
  state() {
    return {
      userId: 0,
      companyId: 0,
      compnayType: CompanyTypeEnum.ONLY,
      companyChldren: [],
      accessToken: '',
      expiresIn: 0,
      role: AccessLevelEnum.EMPLOYEE,
    }
  },
  mutations: {
    setUser(state, user: userAuthInterface) {
      state.userId = user.userId
      state.companyId = user.companyId
      state.accessToken = user.accessToken
      state.companyType = user.companyType
      state.companyChildren = user.companyChildren
      state.expiresIn = user.expiresIn
      state.role = AccessLevelEnum[user.role]
    },
    logoff(state) {
      state.userId = 0
      state.companyId = 0
      state.compnayType = CompanyTypeEnum.ONLY
      state.companyChldren = []
      state.accessToken = ''
      state.expiresIn = 0
      state.role = AccessLevelEnum.EMPLOYEE
    },
  },
  actions: {
    setUser({ commit }, user: userAuthInterface) {
      commit('setUser', user)
    },
  },
}

export default userStore
